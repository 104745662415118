import React from 'react';

import Layout from '../components/Layout';
import Footer from '../components/Footer';
import Header from '../components/Header';
import EmailForm from '../components/EmailForm';
import SlideShow from '../components/SlideShow';

var settings = {
  images: [
    { url: require('../assets/images/bg01.jpg').default, position: 'center' },
    { url: require('../assets/images/bg02.jpg').default, position: 'center' },
    { url: require('../assets/images/bg03.jpg').default, position: 'center' },
  ],
  // Delay.
  delay: 6000,
};

const IndexPage = () => (
  <Layout>
    <header id="header">
      <h1>Thanks. Check your email!</h1>
      <p>If it hasn't arrived, check your spambox and make sure to whitelist all emails from <em>hebrewvirtually.com</em>.</p>
    </header>
    <Footer />
    <SlideShow settings={settings} />
  </Layout>
);

export default IndexPage;
